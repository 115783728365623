<template>
  <div id="account-setting-sharing" class="container">
    <div class="page-header mb-1">
      <strong>ตั้งค่า แบ่งหุ้น/เก็บของ</strong>
    </div>

    <ol class="breadcrumb px-2 py-1 m-0 mb-2 border-primary">
      <li class="breadcrumb-item" v-for="breadcrumb in Breadcrumbs" :class="[{'active': breadcrumb.accountId===parentAccountId}]">
        <span v-if="breadcrumb.accountId===parentAccountId">{{ breadcrumb.username }}</span>
        <a v-else href="#" @click="toggleChildAccount(breadcrumb.accountId)">{{ breadcrumb.username }}</a>
      </li>
    </ol>

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li v-for="group in groups" :key="group._id" class="nav-item">
            <span class="nav-link" :class="[{'active':tabActiveGroup===group._id}]" @click="tabActiveGroupId=group._id">{{ group.groupTitle }}</span>
          </li>
        </ul>
      </div>

      <div class="p-2">
        <table class="table-accounts table table-hover table-bordered mb-0 table-primary">
          <thead>
            <tr>
              <th class="text-center" rowspan="3">ลำดับ</th>
              <th class="text-center" rowspan="2">เลือก</th>
              <th class="text-center" rowspan="2">
                ชื่อใช้งาน
                <b-form-checkbox
                  v-model="showName"
                  :value="true"
                  :unchecked-value="false"
                  class="mt-2 font-weight-normal"
                >
                  แสดงชื่อ
                </b-form-checkbox>
              </th>
              <th class="text-center" rowspan="2">ระดับ</th>
              <th class="text-center" colspan="4">แบ่งหุ้น</th>
              <th class="text-center" rowspan="2">แก้ไข</th>
            </tr>
            <tr>
              <th class="text-center">% ได้รับ</th>
              <th class="text-center">% แบ่งหุ้น</th>
              <th class="text-center">% เก็บของ</th>
              <th class="text-center">เก็บของเกิน</th>
            </tr>
            <tr>
              <th class="text-center p-0">
                <b-form-checkbox :disabled="!permSetting" v-model="checkAll" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th colspan="3" rowspan="2" class="p-1 font-weight-normal">
                <multiselect
                  v-model="selectedGroups"
                  tag-placeholder="ค้นหาหวย"
                  placeholder="เลือกหวย"

                  group-values="groups"
                  group-label="title"
                  :group-select="true"
                  label="groupTitle"
                  track-by="_id"

                  selectLabel="เลือก"
                  :options="groupOptions"
                  :multiple="true"
                  :taggable="true"
                  :hideSelected="true"
                  :disabled="!bulkAccountIds.length"
                ></multiselect>
              </th>
              <th>
                <b-form-select
                  v-model="bulkShare"
                  :disabled="!bulkAccountIds.length"
                  :options="shareOptions"
                  size="md"
                ></b-form-select>
              </th>
              <th class="text-center text-info" style="font-size: 110%;">
                <span v-if="bulkAccountIds.length">{{ (refSetting[tabActiveGroupId].share.receive - bulkShare) | valFormat }}</span>
                <span v-else>-</span>
              </th>
              <th class="text-center p-0">
                <b-form-checkbox v-model="bulkOver" :disabled="!bulkAccountIds.length" class="m-0" :value="true" :unchecked-value="false" />
              </th>
              <th class="text-center p-0"><b-button variant="success" size="sm" :disabled="!bulkAccountIds.length" @click="saveBulk">บันทึก</b-button></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, index) in items" :key="account._id">
              <td width="5%" class="text-center">{{ index+1 }}</td>
              <td width="5%" class="text-center">
                <b-form-checkbox
                  v-model="bulkAccountIds"
                  :value="account._id"
                  :disabled="!account.groups[tabActiveGroupId].isAvailable || !permSetting"
                />
              </td>
              <td class="text-center p-0">
                <div class="username">
                  <a v-if="account.role==='Agent'" href="#" @click="toggleChildAccount(account._id)">{{ account.user.username }}</a>
                  <span v-else>{{ account.user.username }}</span>
                </div>
                <small v-if="showName" class="text-secondary">({{ account.user.firstName }})</small>
              </td>
              <td width="15%" class="text-center"><AccountRole :role="account.role" /></td>
              <td width="10%" class="text-center">{{ refSetting[tabActiveGroupId].share.receive | valFormat}}</td>
              <td width="10%" class="text-center">{{ account.groups[tabActiveGroupId].share.receive | valFormat }}</td>
              <td width="10%" class="text-center">{{ (refSetting[tabActiveGroupId].share.receive - account.groups[tabActiveGroupId].share.receive) | valFormat }}</td>
              <td width="10%" class="text-center">
                <i v-if="account.groups[tabActiveGroupId].share.over" class="fas fa-check fa-lg text-success"></i>
                <i v-else class="fas fa-times fa-lg text-danger"></i>
              </td>
              <td width="6%" class="text-center">
                <button :disabled="!account.groups[tabActiveGroupId].isAvailable || !permSetting" class="btn btn-sm btn-outline-secondary" @click="editSharing(account)"><i class="fas fa-cog"></i></button>
              </td>
            </tr>
            <tr v-if="!items.length">
              <td class="text-center" :colspan="8">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <EditShareModal :data="editData" :refData="refData" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import AccountSettingService from '@/services/AccountSettingService'
import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import EditShareModal from './components/EditShareModal'

export default {
  name: 'SettingSharing',
  components: {
    Multiselect,
    EditShareModal
  },
  data() {
    return {
      accountId: this.$store.state.account._id,
      tabActiveGroupId: null,
      Breadcrumbs: [],
      refSetting: {},
      showName: false,
      accounts: [],
      editData: null,
      isShowModal: false,
      bulkAccountIds: [],
      selectedGroups: [],
      checkAll: false,
      bulkOver: false,
      bulkShare: 0
    }
  },
  computed: {
    currentAccountId() {
      return this.$store.state.account._id
    },
    parentAccountId() {
      if(this.Breadcrumbs.length === 0) {
        return this.currentAccountId
      }else{
        return this.Breadcrumbs[this.Breadcrumbs.length - 1].accountId
      }
    },
    groups() {
      return this.$store.state.marketGroups.filter((group)=>{
        // return group.status === 'Active' && group.groupType === 'single' && this.refSetting[group._id]?.isAvailable
        return group.status === 'Active' && this.refSetting[group._id]?.isAvailable && group.groupType === 'single'
      })
      .map((group)=>{
        return {
          _id: group._id,
          groupTitle: group.groupTitle
        }
      })
    },
    tabActiveGroup() {
      return this.tabActiveGroupId
    },
    groupOptions() {
      return this.groups.reduce((options, group)=>{
        options[0].groups.push(group)
        return options
      }, [{
        title: 'เลือกทั้งหมด',
        groups: []
      }])
    },
    items() {
      return this.accounts.filter((acc)=>{
        return acc.groups[this.tabActiveGroupId].isAvailable
      })
    },
    shareOptions() {

      if(!this.refSetting || !this.tabActiveGroup)
        return []

      const maxShare = this.refSetting[this.tabActiveGroup].share.receive
      let sharePercents = []
      for(let i=maxShare; i>=0; i-=0.5) {
        sharePercents.push({
          text: i.toFixed(1),
          value: i
        })
      }
      return sharePercents
    },
    refData() {
      return this.refSetting[this.tabActiveGroupId]?.share
    },
    userRole() {
      return this.$store.state.userProfile.userRole
    },
    permSetting() {
      const permission = this.$store.state.account?.permission || {}

      return this.userRole === 'Owner' || permission?.MemberSharing === 'w'
    }
  },
  watch: {
    checkAll(n, o) {
      this.bulkAccountIds = []
      if(n){
        for(const item of this.items) {
          if(item.groups[this.tabActiveGroupId].isAvailable) {
            this.bulkAccountIds.push(item._id)
          }
        }
      }
    },
    tabActiveGroup(n, o) {
      if(n) {

        /**
         * ตั้งค่าเลือกกลุ่ม
         */
        const group = this.groups.find((g)=>{
          return g._id === n
        })
        if(group) {
          this.selectedGroups = [{...group}]
        }

        this.resetBulk()
      }
    }
  },
  methods: {
    loadAccounts() {
      AccountSettingService.getAccountSettingSharing(this.accountId)
      .then((response) => {
        if(response.success) {
          this.accounts = response.data.accounts
          this.Breadcrumbs = response.data.parents
          this.refSetting = response.data.refSetting

          if(!this.tabActiveGroupId) {
            this.tabActiveGroupId = this.groups[0]?._id
          }
        }
      })
    },
    toggleChildAccount(accountId) {
      this.accountId = accountId
      this.resetBulk()
      this.loadAccounts()
    },
    resetBulk() {
      this.checkAll = false
      this.keepOver = false
      this.bulkAccountIds = []
    },
    modalClosed(needReload) {
      this.isShowModal = false
      if(needReload) {
        this.loadAccounts()
      }
    },
    editSharing(account) {
      console.log(account.user.username)
      this.editData = {
        accountId: account._id,
        username: account.user.username,
        groupId: this.tabActiveGroupId,
        groupTitle: this.refSetting[this.tabActiveGroupId].groupTitle,
        share: account.groups[this.tabActiveGroupId].share
      }
      this.isShowModal = true
      console.log(this.editData)
    },
    saveBulk() {
      try {
        if(!this.bulkAccountIds.length)
          throw new Error('กรุณาเลือกสมาชิก')

        if(!this.selectedGroups.length)
          throw new Error('กรุณาเลือกกลุ่มหวย')

        /**
         * id กลุ่มที่เลือกแก้ไข
         */
        const groupIds = this.selectedGroups.map((group)=>{
          return group._id
        })

        const updateData = {
          parentAccountId: this.accountId || this.$store.state.account._id,
          accountIds: this.bulkAccountIds,
          groupIds: groupIds,
          share: {
            receive: this.bulkShare,
            referral: this.refSetting[this.tabActiveGroupId].share.receive - this.bulkShare,
            over: this.bulkOver
          }
        }

        AccountSettingService.updateAccountSettingShares(updateData)
        .then((response) => {
          if(response.success) {
            this.resetBulk()
            this.$notify({
              type: 'success',
              title: 'สำเร็จ!',
              text: 'บันทึกเสร็จเรียบร้อย',
            })
          }else{
            throw new Error(response.data)
          }
        })
        .catch((e) => {
          console.log(e.message)
          cAlert({
          ...e,
            title: 'ไม่สำเร็จ!',
            text: e?.message || 'แก้ไขข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
            icon: 'error',
            confirmButtonText: 'OK'
          })
        })
        .finally(() => {
          this.loadAccounts()
        })
      }
      catch(e) {
        cAlert({
          ...e,
          text: e.message,
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }
    },
  },
  filters: {
    valFormat(val) {
      return val.toFixed(1)
    }
  },
  created() {
    this.tabActiveGroupId = this.groups[0]?._id

    if(this.groups.length === 0) {
      this.$store.dispatch('reloadMarketGroups')
    }
  },
  mounted() {
    this.loadAccounts()
  },
}
</script>
<style lang="scss" scoped>
.breadcrumb {
  background-color: #FFF;
  border-radius: 10px;
}

.nav-link {
  cursor: pointer;
}

.table-accounts {
  thead {
    tr {
      th {
        font-size: 90%;
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        line-height: 1;

        div.username {
          font-size: 110%;
        }
        small {
          font-size: 75%;
        }
      }
    }
  }
}
</style>
